import { mapToArray } from '../..';
import { PaymentMethod } from '../../../types/types';
import { SlotService, SlotServices } from '../../state/types';

export const shouldDisplayCheckoutWarning = (slotServices: SlotServices) => {
  const services = mapToArray<SlotService>(slotServices);
  return (
    services.length === 1 &&
    services[0].selectedPaymentType !== PaymentMethod.ONLINE &&
    (services[0].service.hasCancellationFee ||
      services[0].service.hasSaveCreditCardPolicy)
  );
};
